import dayjs from 'dayjs'
import { useMemo, useState } from 'react'
import { NotificationData } from '../type'
import { NotificationType, NotificationTypeDisplayName } from '@components/Notification/realtime-notification/types'
import ButtonLink from '@components/ButtonLink'
import store from '../store'
import DisplayText from '@components/DisplayText/DisplayText'
import { Checkbox, Spin } from 'antd'
import styled from 'styled-components'
import ModuleDetailLink from '@components/AppComponents/ModuleDetailLink'
import { FROM_MODULE_TYPE } from '@utils/constants'
import classname from 'classnames'
interface Props {
  data: NotificationData
}

const CustomCheckbox = styled(Checkbox)`
  .ant-checkbox-inner::after {
    width: 12px;
    height: 12px;
    border-radius: 12px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #1890ff;
  }

  &.checked .ant-checkbox-inner {
    border-color: white; /* Green border when checked */
    background-color: white; /* Green background when checked */
  }

  .ant-checkbox-inner {
    width: 12px;
    height: 12px;
    border-radius: 50%; /* Make it rounded */
    border: 2px solid #d9d9d9; /* Default border color */
  }
`
const getNotificationTypeDisplayName = (type: NotificationType) => {
  return NotificationTypeDisplayName[type.toString()] || type.toString()
}

const NotificationItem = ({ data }: Props) => {
  const [isShowMore, setIsShowMore] = useState(false)
  const [isLoadingMoreUpdate, setIsLoadingMoreUpdate] = useState(false)
  const [isRead, setIsRead] = useState(data.isRead)
  const [isHovered, setIsHovered] = useState(false)
  const handleMouseEnter = () => setIsHovered(true)
  const handleMouseLeave = () => setIsHovered(false)

  const relativeTime = useMemo(() => {
    if (!data.sentTime) return ''
    return dayjs(data.sentTime).fromNow()
  }, [data.sentTime])

  const sentTimeString = useMemo(() => {
    if (!data.sentTime) return ''
    return dayjs(data.sentTime).format('DD MMM YYYY HH:mm')
  }, [data.sentTime])

  const title = useMemo(() => {
    return data.title
    // const typeName = getNotificationTypeDisplayName(data.type)
    // return `${typeName}${data.templateKey ? `: ${data.templateKey}` : ''}`
  }, [data.type])

  const handleShowMoreUpdate = async () => {
    setIsLoadingMoreUpdate(true)
    await store.fetchMoreUpdate(data)
    setIsLoadingMoreUpdate(false)
    setIsShowMore(true)
  }

  const handleShowLessUpdate = () => {
    setIsShowMore(false)
  }

  const handleToggleIsRead = async () => {
    if (data.isRead) {
      data.isRead = false
      setIsRead(false)
      await store.markAsUnread(data.id)
    } else {
      data.isRead = true
      setIsRead(true)
      await store.markAsRead(data.id)
    }

    store.getSummary()
  }

  return (
    <div className={classname(data.isRead ? '' : 'bg-grey-100', 'mb-1')}>
      <div className="p-2 rounded-md hover:bg-grey-100" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
        <div className="flex gap-2 justify-between">
          <div className="font-bold text-sm">
            <div className="max-h-10 overflow-y-clip" title={title}>
              {title}
            </div>

            {/* {!data.creator && <span className="mr-2 italic text-grey-400">(Auto email)</span>} */}
          </div>
          <div className="flex gap-2 items-start">
            <span className="text-grey-450 whitespace-nowrap" title={sentTimeString}>
              {relativeTime}
            </span>
            <CustomCheckbox
              checked={!isRead}
              className="rounded mb-0"
              onClick={e => {
                handleToggleIsRead()
              }}
              title={isRead ? 'Mark as unread' : 'Mark as read'}
              style={{ visibility: isHovered || !isRead ? 'visible' : 'hidden' }}
            />
          </div>
        </div>
        <div className="text-sm flex gap-2 items-center">
          {data.objectReferenceName && (
            <div>
              <ModuleDetailLink
                id={data.objectId}
                module={data.fromModule as FROM_MODULE_TYPE}
                text={data.objectReferenceName}
                callbackBeforeOnClick={() => {
                  if (!data.isRead) {
                    store.markAsRead(data.id)
                  }
                }}
              />
              {' - '}
            </div>
          )}
          {data.creator && (
            <span className="mr-2">
              Updated By: <span title={data.creator?.email}>{data.creator?.name}</span>
            </span>
          )}

          {!isShowMore && data.totalUpdateCount > 1 && (
            <ButtonLink className="flex items-center gap-2" onClick={handleShowMoreUpdate}>
              + {data.totalUpdateCount - 1} updates {isLoadingMoreUpdate && <Spin size="small" spinning={true} />}
            </ButtonLink>
          )}
        </div>
        {/* <div className="text-grey-450">{data.title}</div> */}
        <div className="text-grey-450 max-h-10 overflow-y-clip">
          <DisplayText maxLength={200} text={data.content} isHtmlContent />
        </div>
      </div>
      {isShowMore && data.moreUpdates?.length > 0 && (
        <div className="pl-3">
          <div className="border-t-blue-300 border-t-2">
            {data.moreUpdates.map(data => {
              return <NotificationItem data={data} />
            })}
          </div>

          {isShowMore && (
            <ButtonLink className="text-sm ml-2" onClick={handleShowLessUpdate}>
              Show less
            </ButtonLink>
          )}
        </div>
      )}
    </div>
  )
}

export default NotificationItem
